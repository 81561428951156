import React, {useEffect, useRef, useState,} from 'react';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider, Grid, Container, Box } from '@mui/material';
import theme from '../MUI/Theme';

import { Typography, Card, Dialog, DialogContent, DialogContentText, Button } from '@mui/material';

import LoginCard from '../Login/LoginCard';
import VideoSelect from './CameraControls/VideoSelect';
import AudioSelect from './CameraControls/AudioSelect';
import SpeakerSelect from './CameraControls/SpeakerSelect';
import FastControls from './CameraControls/FastControls';

import Arcadyan from './Arcadyan/Arcadyan';

import useStyles from './useStyles';
import './Preview.css'

import { setParticipantConstraints, login, setRoomSettings } from '../../Store/Reducers/ParticipantReducer'
import {
  doPreview,
  getDevicesList,
  onDevicesUpdate,
  setWTAudioConstraints,
  setWTVideoConstraints,
} from "../../WT";

const LS_LAST_VIDEO_DEVICE = 'SCN_LAST_VIDEO_DEVICE';

const Preview = ({ history, isArcadyan }) => {
  const dispatch = useDispatch();

  const [mediaList, setMediaList] = useState({
    camera: [],
    microphone: [],
    speaker: [],
  })

  const lastVideoDevice = localStorage.getItem(LS_LAST_VIDEO_DEVICE);

  const [video, setVideo] = useState(lastVideoDevice || '')
  const [audio, setAudio] = useState('default')
  const [speaker, setSpeaker] = useState('default')

  const [videoEnabled, setVideoEnabled] = useState(false)
  const [audioEnabled, setAudioEnabled] = useState(true)
  const [isFirefox] = useState(typeof InstallTrigger !== 'undefined')
  const [isSafari] = useState(/constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification)))

  const [videoPendingState, setVideoPendingState] = useState(false)
  const streamRef = useRef(null);

  const { rejected, kicked } = useSelector(state => state.participant)

  const [rejectDialogOpened, setRejectDialogOpened] = useState(rejected);
  const [kickedDialogOpened, setKickedDialogOpened] = useState(kicked);

  const getConstraints = (audioDevice=null, videoDevice=null) => {
    if (audioEnabled && audioDevice === null) {
      audioDevice = audio;
    }

    if (videoDevice === null) {
      videoDevice = video;
    }

    const constraints = {
      audio: audioDevice ? { deviceId: audioDevice } : false,
      video: videoDevice ? { deviceId: videoDevice } : false,
    };

    constraints.audio = audioEnabled ? constraints.audio : false;
    constraints.video = videoEnabled ? constraints.video : false;

    return constraints;
  }

  const handleRejectClose = () => {
    setRejectDialogOpened(false);
  };

  const handleKickedClose = () => {
    setKickedDialogOpened(false);
  };

  const isLocalhost = /localhost:3000/.test(window.location.href);

  useEffect(() => {

  }, [rejected, kicked]);

  useEffect(() => {
    return () => {
      if(streamRef.current) {
        streamRef.current.getTracks().forEach(track => track && track.stop());
      }
    }
  }, []);

  // eslint-disable-next-line
  const classes = useStyles();

  const getDevices = async () => {
    try {
      await connectInitialDevices()

      const devices = await getDevicesList()

      setMediaList(devices)

      setVideo(video || devices.camera[0].value)

      if (isFirefox || isSafari) {
        setAudio(devices.microphone[0].value)
      }

      return devices
    } catch (e) {
      console.log('error - getDevices', e.message)
    }
  }

  const connectInitialDevices = async () => {
    try {
      const constraints = getConstraints();
      constraints.video = true;
      
      try {
        if (lastVideoDevice) {
          constraints.video = { deviceId: { exact: lastVideoDevice } };
        }
      } catch (e) {/*pass*/}

      streamRef.current = await doPreview(constraints);

      const videoElem = document.getElementById('video-preview');

      if ("srcObject" in videoElem) {
        videoElem.srcObject = streamRef.current;
      }
      videoElem.onloadedmetadata = function (e) {
        setVideoEnabled(true)
        videoElem.play();
      };
    } catch (error) {
      console.log('error - connectInitialDevices', error)
      setVideoEnabled(false)
    }
  }

  const toggleCamera = async () => {
    const video = document.getElementById('video-preview');

    video.style.visibility = videoEnabled ? 'hidden' : 'visible';

    const constraints = getConstraints(null, !videoEnabled);

    try {
      streamRef.current = await doPreview(constraints);
    } catch (e) {
      console.log('error - doPreview', e.message);
    }

    setVideoEnabled(prev => !prev);
  }

  const handleDevice = async (event, type) => {
    const value = event.target.value

    if (type === 'microphone') {
      changeAudioDevice(value)
    }

    if (type === 'camera') {
      changeVideoDevice(value)
    }

    if (type === 'speaker') {
      changeOutputDevice(value)
      setSpeaker(value)
    }
  };

  const changeVideoDevice = async (value) => {
    setVideoPendingState(false)
    setVideoPendingState(true)

    const video = document.getElementById('video-preview');

    const constraints = getConstraints(null, value);

    streamRef.current = await doPreview(constraints);

    video.srcObject = streamRef.current;

    video.onloadedmetadata = function (e) {
      setVideoPendingState(false)
      setVideo(value)
      video.play();
    };

    try {
      localStorage.setItem(LS_LAST_VIDEO_DEVICE, value);
    } catch (e) {/*pass*/}
  };

  const changeAudioDevice = async (value) => {
    const videoElem = document.getElementById('video-preview');

    if (isFirefox) {
      videoElem.srcObject.getTracks().forEach(track => track.stop())
      console.log("videoElem.srcObject", videoElem.srcObject)
    }

    streamRef.current = await doPreview({
      video: { width: 384, height: 216, deviceId: { exact: video } },
      audio: { deviceId: { exact: value } },
    });

    videoElem.srcObject = streamRef.current;

    videoElem.srcObject.getAudioTracks()[0].enabled = audioEnabled

    videoElem.onloadedmetadata = function (e) {
      setAudio(value)
      videoElem.play();
    };
  }

  const changeOutputDevice = async (value) => {
    const videoElem = document.getElementById('video-preview');

    videoElem.setSinkId(value);
  }

  const submitPreviewData = async (e, {room, type}) => {
    if(streamRef.current) {
      streamRef.current.getTracks().forEach(track => track && track.stop())
    }

    const constraints = getConstraints();
    streamRef.current = await doPreview(constraints);

    if (type === 'viewer') {
      setWTAudioConstraints(false);
      setWTVideoConstraints(false);
    } else {
      setWTAudioConstraints(constraints.audio);
      setWTVideoConstraints(constraints.video);
    }
    
    dispatch(setParticipantConstraints(constraints));
    history.push(`/session?room=${room}`);
  }

  useEffect(() => {
    getDevices()

    onDevicesUpdate((devices)=> {
      setMediaList(devices)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isLocalhost && new URL(window.location.href).searchParams.get('skip')) {
      setTimeout(() => {
        document.querySelector('.login-form-btns button').click();
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mediaList?.camera.length) {
      const hasLastVideoDevice = mediaList?.camera.some(item => item.value === lastVideoDevice);

      if (!hasLastVideoDevice) {
        localStorage.removeItem(LS_LAST_VIDEO_DEVICE);
      }
    }
  }, [mediaList, lastVideoDevice])

  if (isArcadyan) {
    return (
      <ThemeProvider theme={theme}>
        <Arcadyan
          videoPendingState={videoPendingState}
          videoEnabled={videoEnabled}
          submitPreviewData={room => {
            dispatch(setRoomSettings({viewer: false}));
            dispatch(login('arcadyan', room, Boolean(new URL(window.location.href).searchParams.get('isAdmin'))))

            submitPreviewData(null, {room});
          }}
          disabled={videoEnabled === null}
        />
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <Container sx={{
        display: 'flex',
        overflow: 'auto',
        height: '100%',
        paddingTop: 4,
        paddingBottom: 4,
      }}>
        <Grid container spacing={2} sx={{ alignItems: 'center' }}>
          <Grid
            item
            xs={12}
            md={7}
            order={{ xs: 3, md: 2 }}
          >
            <Card
              sx={{
                position: 'relative',
                marginBottom: {
                  xs: 2,
                  md: 0,
                },
              }}>
              <div className='p-camera'>
                {videoPendingState && videoEnabled !== false && (
                  <div className='p-camera-placeholder'>
                    Preparing camera...
                  </div>
                )}

                {videoEnabled === null && (
                  <div className='p-camera-placeholder'>
                    Getting configuration...
                  </div>
                )}

                {videoEnabled === false && (
                  <div className='p-camera-placeholder'>
                    Your camera is turned off
                  </div>
                )}
                <video id='video-preview' muted={true} className='p-block' />
              </div>

              {videoEnabled !== null && (
                <ThemeProvider theme={theme}>
                  <FastControls
                    videoEnabled={videoEnabled}
                    toggleCamera={toggleCamera}
                    audioEnabled={audioEnabled}
                    setAudioEnabled={setAudioEnabled}
                  />
                </ThemeProvider>
              )}
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              alignSelf: {
                xs: 'center',
              },
            }}
            order={{ xs: 2, md: 3 }}
          >
            <Box sx={{
              paddingLeft: {
                xs: 0,
                md: 3,
              }
            }}>
              <Typography
                variant='h5'
                component='h5'
                sx={{ color: '#333', textAlign: 'center', lineHeight: 1, marginBottom: '16px' }}
              >
                Are you ready to join?
              </Typography>
              <LoginCard
                handleJoin={submitPreviewData}
                handleJoinAsAViewer={submitPreviewData}
                disabled={videoEnabled === null}
                viewerOnly={!videoEnabled && !audioEnabled}
              />
              <div className='preview-controls-wrapper'>
                <VideoSelect
                  mediaList={mediaList}
                  handleDevice={handleDevice}
                  video={video}
                  videoEnabled={videoEnabled}
                />
                <AudioSelect
                  audioEnabled={audioEnabled}
                  mediaList={mediaList}
                  handleDevice={handleDevice}
                  audio={audio}
                />
                {((!isFirefox && !isSafari) || !mediaList?.speaker) && (
                  <SpeakerSelect
                    speaker={speaker}
                    mediaList={mediaList}
                    handleDevice={handleDevice}
                  />
                )}
              </div>
            </Box>
          </Grid>
        </Grid>
        <Dialog
          open={rejectDialogOpened}
          onClose={handleRejectClose}
        >
          <DialogContent>
            <DialogContentText>
              The organizer could not admit you into the meeting at this time, please try again
            </DialogContentText>
          </DialogContent>
        </Dialog>

        <Dialog
          open={kickedDialogOpened}
          onClose={handleKickedClose}
        >
          <DialogContent>
            <DialogContentText>
              The meeting has ended, if you need to go back to the meeting please enter your name again and join the session
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Container>

      {isLocalhost ? (
        <Button
          color="secondary"
          variant="contained"
          sx={{position: 'absolute', bottom: '16px', right: '16px'}}
          onClick={() => {
            for (let i = 0; i < 3; i++) {
              window.open('/vikt?skip=true','_blank');
            }
          }}
        >
          test + 3
        </Button>
      ) : null}
    </ThemeProvider>
  )
};

export default withRouter(Preview)
