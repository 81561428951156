import React, {useEffect, useState, useRef, useCallback} from 'react'
import {Fab, Tooltip} from '@mui/material';
import {Chat as ChatIcon} from '@mui/icons-material';
import Cookies from 'js-cookie';

import {onMessageReceive, sendMessage} from "WT";

import Board from './Board';

import './ExternalChat.css'
import {useSelector} from "react-redux";
import {PChatSDK} from "@sscale/pchatsdk";

const ExternalChat = ({participants}) => {
    const {token: wt_token, isAdmin} = useSelector(state => state.participant)

    const [isOpen, setIsOpen] = useState(false);
    const [unreadMessage, setUnreadMessage] = useState(false);
    const [connected, setConnected] = useState(false);
    const [room, setRoom] = useState(null);
    const roomRef = useRef(null);
    const [id, setId] = useState('');
    const PChatRef = useRef(null);

    const toggleChat = () => {
        setIsOpen(!isOpen);
        setUnreadMessage(false);
    }

    const updateRoomInfo = useCallback((data) => {
        const current = roomRef.current;

        if (!current || current.time > data.time) {
            setRoom(data);
            roomRef.current = data;

            return true;
        }

        return false;
    }, [])

    const localParticipant = participants.find(p => p.local);

    useEffect(() => {
        if (!localParticipant || !id || !room) {
            return;
        }

        let canceled = false;

        setConnected(false);

        const PChat = new PChatSDK();
        PChatRef.current = PChat;

        const connect = async () => {
            const data = await fetch(`https://public-chat.management.sceenic.co/token`, {
                method: 'POST',
                body: JSON.stringify({user_id: id}),
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((response) => response.json());

            const token = data.token;

            await PChat.connect(room.id, localParticipant.participantName, token);

            if (!canceled) {
                setConnected(true);
            }
        }

        const connecting = connect().catch((e) => {
            console.log('Chat connect error, retrying', e);
            return connect().catch(e => console.log('Chat connect error', e));
        })

        return () => {
            canceled = true;
            connecting.then(() => PChat.disconnect());
        }
    }, [localParticipant, room, id]);

    useEffect(() => {
        onMessageReceive((message) => {
            try {
                const [, content] = message.message.split('chat_');
                const {tag, data} = JSON.parse(content);
                if (tag === 'CHAT_ROOM_ID') {
                    updateRoomInfo(data)
                }
            } catch(e) {
                console.log(e)
            }
        });

        return () => onMessageReceive(() => {});
    }, [updateRoomInfo]);

    useEffect(() => {
        if (!roomRef.current) {
            return;
        }

        sendMessage(`chat_${JSON.stringify({tag: 'CHAT_ROOM_ID', data: roomRef.current})}`);
    }, [participants]);

    useEffect(() => {
        if (isAdmin) {
            const roomId = Math.random()
                .toString(36)
                .replace(/[^a-z]+/g, '');

            const info = {id: roomId, time: new Date().toISOString()};

            const updated = updateRoomInfo(info);

            if (updated) {
                sendMessage(`chat_${JSON.stringify({tag: 'CHAT_ROOM_ID', data: info})}`);
            }
        }
    }, [isAdmin, updateRoomInfo])

    useEffect(() => {
        if (!wt_token) {
            return;
        }

        let id = Cookies.get(wt_token);
        if (!id) {
            id = Math.random()
                .toString(36)
                .replace(/[^a-z]+/g, '');
            Cookies.set(wt_token, id, { expires: 1 });
        }

        setId(id);
    }, [wt_token])

    useEffect(() => {
        if (!PChatRef.current) {
            return;
        }

        const chat = PChatRef.current;

        const handler = () => {
            setUnreadMessage(true);
        }

        chat.on(chat.Events.MESSAGE, handler);

        return () => {
            chat.off(chat.Events.MESSAGE, handler);
        }
    }, [connected])

    if (!connected) {
        return null;
    }

    return (<>
        <Tooltip title='Chat with participants' placement='top'>
            <Fab
                size="medium"
                color="primary"
                aria-label='Chat with participants'
                sx={{marginLeft: 1, marginRight: 1, backgroundColor: 'rgba(25, 118, 210, .7)'}}
                onClick={toggleChat}
                tabIndex={6}
            >
                {!isOpen && unreadMessage ? <div className='externalChat-unread'/> : null}
                <ChatIcon fontSize='large'/>
            </Fab>
        </Tooltip>


        <div
            style={{transform: isOpen ? 'translate(0)' : 'translate(calc(100% + 100px))'}}
            className="externalChat"
        >
            <Board chat={PChatRef.current} />
        </div>

    </>)
};

export default ExternalChat;
