import React, {useEffect, useRef, forwardRef} from 'react';
import classnames from "classnames";
import './SmartVideo.css'

const SmartVideo = forwardRef(function SmartVideo({className, stream, onDoubleClick, isLocal, participantId}, fRef) {
    const ref = useRef();

    useEffect(() => {
        const container = ref.current;
        if (!container) {
            return;
        }

        const active = container.querySelector('[data-active="true"]');
        const disabled = container.querySelector('[data-active="false"]');
        if (!active || !disabled) {
            return;
        }

        disabled.srcObject = stream;
        disabled.oncanplaythrough = () => {
            disabled.dataset.active = 'true';
            active.dataset.active = 'false';

            disabled.play();

            active.srcObject = null;

            disabled.oncanplaythrough = null;

            fRef.current = disabled;
        }
    }, [fRef, participantId, stream]);

    return (
        <div style={{display: 'contents'}} ref={ref}>
            <video
                className={classnames(className, 'smart-video')}
                id={`video-${participantId}`}
                playsInline
                autoPlay
                disablePictureInPicture
                muted={isLocal}
                onDoubleClick={onDoubleClick}
                data-active="true"
            />
            <video
                className={classnames(className, 'smart-video')}
                id={`additionalVideo-${participantId}`}
                playsInline
                autoPlay
                disablePictureInPicture
                muted={isLocal}
                onDoubleClick={onDoubleClick}
                data-active="false"
            />
        </div>
    );
});

export default SmartVideo;
