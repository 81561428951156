import React from 'react';
import { Box, Card } from '@mui/material';

import Messages from './Messages/Messages';
import MsgInput from './MsgInput';

import './style.css';

const Board = ({chat}) => {
    return (
        <Card className='board-root'>
            <Messages chat={chat} />

            <Box className='board-actions'>
                <MsgInput chat={chat} />
            </Box>
        </Card>
    );
};

export default React.memo(Board);
