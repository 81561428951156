import React from 'react';
import classNames from 'classnames';

import './Message.css';

const Message = ({ text, time, author, authorColor, isMy }) => {
    const msgDate = new Date(time);
    const h = String(msgDate.getHours()).padStart(2, '0');
    const m = String(msgDate.getMinutes()).padStart(2, '0');

    const parsedText = text
        .replace('"', "%22")
        .replace(/http(s)*:\/\/[^\s]+\.(png|jpg|jpeg|gif)[^\s]*/gmi, '<a href="$&" target="_blank"><img src="$&" width="100%" /></a>')
        .replace(/[^"]http(s)*:\/\/[^\s]*/gmi, '<a href="$&" target="_blank">$&</a>')
        .replace(/^http(s)*:\/\/[^\s]*/gmi, '<a href="$&" target="_blank">$&</a>');

    return (
        <div className={classNames('message-root', { 'message-root-my': isMy })}>
            {!isMy && <div className='message-title' style={{ color: authorColor || '#333' }}>{author}</div>}

            <div className='message-msg'>
                <div className={classNames('message-content', { 'message-content-my': isMy })} dangerouslySetInnerHTML={{__html: parsedText}} />
                <div className='message-actions'>
                    <div className='message-actionsTime'>{`${h}:${m}`}</div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(Message);
