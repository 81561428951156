import React, { useCallback, useEffect, useRef } from 'react';

import useForceUpdate from 'Hooks/useForceUpdate';

import colors from '../Colors';

import Message from './Message/Message';
import './Messages.css';

const uColors = {};

const Messages = ({chat}) => {
    const forceUpdate = useForceUpdate();
    const ref = useRef(null);

    // Scroll to bottom forced or if user is on last message
    const scrollToBottom = useCallback((forced) => {
        const $container = ref.current;
        if (!$container) {
            return;
        }

        const offset = $container.scrollHeight - $container.scrollTop - $container.clientHeight;
        if (offset < 100 || forced) {
            $container.scrollTop = $container.scrollHeight;
        }
    }, []);

    // Load more messages on scroll
    const handleScroll = useCallback(() => {
        const $container = ref.current;
        if (!$container) {
            return;
        }

        const top = $container.scrollTop === 0;
        if (!top) {
            return;
        }

        console.log('Loading older messages');

        const scrollHeight = $container.scrollHeight;

        chat.loadOlder()
            .then(() => {
                forceUpdate();
                $container.scrollTop = $container.scrollHeight - scrollHeight;
                console.log('History updated, older messages loaded');
            })
            .catch((e) => {
                console.log('Older messages load error', e);
            });
    }, [forceUpdate, chat]);

    // Subscribe to history updates
    useEffect(() => {
        const onMessage = () => {
            forceUpdate();
            scrollToBottom();
        };

        chat.on(chat.Events.MESSAGE, onMessage);

        return () => {
            chat.off(chat.Events.MESSAGE, onMessage);
        };
    }, [forceUpdate, scrollToBottom, chat]);

    // Initial scroll and history load
    useEffect(() => {
        chat.loadOlder()
            .then(() => {
                forceUpdate();
                scrollToBottom(true);
                console.log('Initial history loaded');
            })
            .catch((e) => {
                console.log('History load error', e);
            });
    }, [forceUpdate, scrollToBottom, chat]);

    return (
        <div className='messages-root' ref={ref} onScroll={handleScroll}>
            {chat.messages.map((item, i) => {
                const authorId = item.user.id;
                let authorColor = uColors[authorId];

                if (!authorColor) {
                    authorColor = colors.shift();
                    colors.push(authorColor);
                    uColors[authorId] = authorColor;
                }

                return (
                    <Message
                        key={item.id}
                        author={item.user.meta.username}
                        authorColor={authorColor}
                        text={item.content}
                        time={item.created_at}
                        isMy={chat.user.id === item.user.id}
                    />
                );
            })}
        </div>
    );
};

export default React.memo(Messages);
