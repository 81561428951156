import axios from 'axios';

const apiRequest = axios.create({
  baseURL: process.env.REACT_APP_CLUSTER_URL,
  headers: {
    'Content-Type': 'application/json',
    'Auth-Token': process.env.REACT_APP_CLUSTER_TOKEN
  }
});

const getStreamingToken = async (room_name) => {
  return await apiRequest.post('/token/v2/', {
    room_name
  });

}

export { getStreamingToken }
