import React from "react";
import {InputLabel, Select, FormControl, MenuItem} from '@mui/material';

import './Controls.css';

const AudioControl = ({audioEnabled, mediaList, handleDevice, audio}) => {

    const renderAudioState = () => {
        return (
            <div className='camera-controls-title'>
                Audio source - <span className={audioEnabled ? 'enabled' : 'disabled'}>{audioEnabled ? 'Enabled' : 'Disabled'}</span>
            </div>
        )
    }

    return (
        <FormControl fullWidth className="camera-control">
            <InputLabel sx={{ top: 4 }}>
                {renderAudioState()}
            </InputLabel>
            <Select
                value={audio}
                onChange={(e) => handleDevice(e, 'microphone')}
                size="small"
            >
                {mediaList?.microphone && mediaList.microphone.map((device, index) => (
                    <MenuItem
                        value={device.value}
                        key={index}
                    >
                        {device.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default AudioControl;