import WT from "@sscale/wtsdk";
import WTDiagnostic from "@sscale/wtsdk/dist/WTWebDiagnostics";
import { publish, on, off } from "../utils/pubsub";

// SESSION

const connect = (token, userName, constraints) => {
    WT.Session.connect(token, (userName || 'Oleh'), constraints);
}

const connectWithoutPublish = (token, userName, audioOnly, constraints) => {
    WT.Session.connectWithoutPublish(token, userName, audioOnly ? {audio: true, video: false} : constraints);
}

const connectAsViewer = (token, userName) => {
    WT.Session.connectAsAViewer(token, (userName || 'Oleh'));
}

const disconnect = () => {
    WT.Session.disconnect()
}

const listenWtError = (cb) => {
    WT.ErrorsListeners.onSessionError(cb)
}
const onConnected = (cb) => {
    WT.SessionListeners.onConnected(cb)
}
const onLocalConnectionResumed = (cb) => {
    WT.ReconnectListeners.onLocalConnectionResumed(cb)
}

const enableWTStats = () => {
    WT.Session.enableStats();
}
const onMosReport = (cb) => {
    WT.SessionListeners.onMosReport(cb)
}

const offMosReport = (cb) => {
    WT.SessionListeners.offMosReport(cb)
}

const setWTAudioConstraints = (constraints) => {
    WT.setAudioConstraints(constraints)
}
const setWTVideoConstraints = (constraints) => {
    WT.setVideoConstraints(constraints)
}

// STREAM
const onLocalStreamCreated = (cb) => {
    WT.SessionListeners.onLocalStreamCreated(cb)
}
const onStreamCreated = (cb) => {
    WT.SessionListeners.onStreamCreated(cb)
}
const setMediaQuality = (width, height, frameRate) => {
    WT.Participant.setMediaQuality({videoWidth: width, videoHeight: height, frameRate})
}

// PARTICIPANT

const onParticipantJoin = (cb) => {
    WT.ParticipantListeners.onParticipantJoin(cb)
}
const onParticipantLeft = (cb) => {
    WT.ParticipantListeners.onParticipantLeft(cb)
}
const onParticipantRejected = (cb) => {
    WT.ParticipantListeners.onParticipantRejected(cb)
}
const disableAudio = () => {
    WT.Participant.disableAudio();
}
const enableAudio = () => {
    WT.Participant.enableAudio();
}
const disableVideo = () => {
    WT.Participant.disableVideo();
}
const enableVideo = () => {
    WT.Participant.enableVideo();
}

const getShownVideoElementId = (participantId) => {
    const videoId = `video-${participantId}`;
    const additionalVideoId = `additionalVideo-${participantId}`;
    const videoElement = document.getElementById(videoId);
    const isVideoElementActive = videoElement.dataset.active === 'true';
    return isVideoElementActive ? videoId : additionalVideoId;
}

const startScreenSharing = async (participantId) => {
    const idForScreenSharing = getShownVideoElementId(participantId);
    await WT.Participant.startScreenSharing(idForScreenSharing);
}
const stopScreenSharing = (participantId) => {
    const idForScreenSharing = getShownVideoElementId(participantId);
    WT.Participant.stopScreenSharing(idForScreenSharing);
}

WT.ParticipantListeners.onParticipantSpeaking(publish.bind(null, 'onParticipantSpeaking'));
const pStartSpeaking = (cb) => {
    on('onParticipantSpeaking', cb);

    return () => off('onParticipantSpeaking', cb);
}

WT.ParticipantListeners.onParticipantStopSpeaking(publish.bind(null, 'onParticipantStopSpeaking'));
const pStopSpeaking = (cb) => {
    on('onParticipantStopSpeaking', cb);

    return () => off('onParticipantStopSpeaking', cb);
}

WT.ParticipantListeners.onParticipantMediaStreamChanged(publish.bind(null, 'onParticipantMediaStreamChanged'));
const pMediaStreamChanged = (cb) => {
    on('onParticipantMediaStreamChanged', cb);

    return () => off('onParticipantMediaStreamChanged', cb);
}

// REMOTE PARTICIPANT
const publishRemoteParticipant = (participantId) => {
    WT.Participant.publishRemoteParticipant(participantId);
}
const disconnectRemoteP = (participantId) => {
    WT.Participant.disconnectRemoteParticipant(participantId)
}

const toggleWTRemoteVideo = (participantId) => {
    WT.Participant.toggleRemoteVideo(participantId);
}

const toggleWTRemoteAudio = (participantId) => {
    WT.Participant.toggleRemoteAudio(participantId);
}

const enabledVideoStatsList = {};
const enableWTVideoStats = (videoElement, cb) => {
    if(!enabledVideoStatsList[videoElement.id]) {
        enabledVideoStatsList[videoElement.id] = new WTDiagnostic.VideoFrameChecker(videoElement, cb);
    }
}

const disableWTVideoStats = (videoElement) => {
    if(enabledVideoStatsList[videoElement.id]) {
        enabledVideoStatsList[videoElement.id].stop();
        delete enabledVideoStatsList[videoElement.id];
    }
};

const isRemoteWTVideoEnabled = (participantId) => {
    return WT.Participant.isRemoteVideoEnabled(participantId)
}
const isRemoteWTAudioEnabled = (participantId) => {
    return WT.Participant.isRemoteAudioEnabled(participantId)
}
// CHAT

const onMessageReceive = (cb) => {
    WT.SessionListeners.onMessageReceived(cb)
}
const sendMessage = (message) => {
    WT.Session.sendMessage(message);
}
const isAudioEnabled = () => {
   return WT.Participant.isAudioEnabled()
}
const isVideoEnabled = () => {
    return WT.Participant.isVideoEnabled()
}

// DEVICES
const getDevicesList = async () => {
    return await WT.getMediaDevices()
}
const onDevicesUpdate = (cb) => {
    WT.onDeviceListUpdates(cb)
}
const changeMediaDevices = async (value, type, localPId) => {
  await WT.ChangeMediaDevices(value, type, localPId)
}
// PREVIEW

const doPreview = async (constraints) => {
   return await WT.doPreview(constraints)
}
export {
    connect,
    disconnect,
    connectAsViewer,
    connectWithoutPublish,
    listenWtError,
    onConnected,
    onLocalConnectionResumed,
    onStreamCreated,
    onParticipantLeft,
    onParticipantJoin,
    onParticipantRejected,
    disconnectRemoteP,
    publishRemoteParticipant,
    onLocalStreamCreated,
    onMessageReceive,
    sendMessage,
    enableVideo,
    enableAudio,
    disableVideo,
    disableAudio,
    startScreenSharing,
    stopScreenSharing,
    isAudioEnabled,
    isVideoEnabled,
    enableWTStats,
    onMosReport,
    offMosReport,
    pStartSpeaking,
    pStopSpeaking,
    pMediaStreamChanged,
    toggleWTRemoteAudio,
    toggleWTRemoteVideo,
    isRemoteWTVideoEnabled,
    isRemoteWTAudioEnabled,
    getDevicesList,
    onDevicesUpdate,
    changeMediaDevices,
    doPreview,
    setWTAudioConstraints,
    setWTVideoConstraints,
    setMediaQuality,
    enableWTVideoStats,
    disableWTVideoStats,
}
