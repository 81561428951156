import React, {useEffect, useState} from 'react';
import classnames from 'classnames';
import Slider from '@material-ui/core/Slider';
import { Button } from '@mui/material';
import './VideoQuality.css'
import {setMediaQuality} from "../../WT";

const VideoQuality = ({showModal, show, localParticipant}) => {
    const [videoWidth, setVideoWidth] = useState(384);
    const [videoHeight, setVideoHeight] = useState(216);
    const [frameRate, setFrameRate] = useState(15);

    const resolutions = {
        128: 72,
        384: 216,
        512: 288,
        640: 360,
        768: 432,
        896: 504,
        1024: 576,
        1152: 648,
        1280: 720,
        1920: 1080,
    };

    const resolutionsByHeight = {};

    const widthMarks = [];
    const heightMarks = [];

    Object.keys(resolutions).forEach(width => {
        const height = resolutions[width];

        widthMarks.push({value: width});
        heightMarks.push({value: height});

        resolutionsByHeight[height] = width;
    });

    const handleChangeWidth = (event, newValue) => {
        setVideoWidth(newValue);
        setVideoHeight(resolutions[newValue]);
    };

    const handleChangeHeight = (event, newValue) => {
        setVideoHeight(newValue);
        setVideoWidth(resolutionsByHeight[newValue]);
    };

    const handleChangeFrameRate = (event, newValue) => {
        setFrameRate(newValue);
    };

    const setVideoQuality = () => {
        setMediaQuality(videoWidth, videoHeight, frameRate);
        showModal(false)
    }

    const settingsClasses = classnames({
        'video-quality-container': true,
    })

    useEffect(() => {
        if (localParticipant) {
            setMediaQuality(videoWidth, videoHeight, frameRate);
        }
    }, [videoWidth, videoHeight, frameRate, localParticipant]);

    if(!show) {
        return (<div></div>)
    }

    return (<>
        <div className='video-quality'>
        <div className={settingsClasses}>
            <div className='video-width-container'>
                <div className='title-text'>Width pxls: {videoWidth}</div>
                <div id='discrete-slider-width' className='slider-container'>
                    <Slider
                        min={72}
                        max={1920}
                        step={null}
                        value={videoWidth}
                        marks={widthMarks}
                        onChange={handleChangeWidth}
                        aria-labelledby='discrete-slider-width'/>
                </div>
            </div>

            <div className='video-width-container'>
                <div className='title-text'>Height pxls: {videoHeight}</div>
                <div id='discrete-slider-restrict' className='slider-container'>
                    <Slider
                        min={72}
                        max={1920}
                        step={null}
                        value={videoHeight}
                        marks={heightMarks}
                        onChange={handleChangeHeight}
                        aria-labelledby='discrete-slider-restrict'/>
                </div>
            </div>

            <div className='video-width-container'>
                <div className='title-text'>Frame Rate: {frameRate}</div>
                <div className='slider-container'>
                    <Slider min={10}
                            max={60}
                            step={5}
                            value={frameRate} onChange={handleChangeFrameRate} aria-labelledby="continuous-slider"/>
                </div>
            </div>

            <div className='video-quality-footer-buttons'>
                <Button onClick={setVideoQuality}>Apply</Button>
                <Button onClick={setVideoQuality}>Close</Button>
            </div>
        </div>
        </div>
    </>)
}

export default VideoQuality;
