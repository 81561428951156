import React, {useEffect, useRef, useState} from 'react';
import classnames from 'classnames';
import {Card, Accordion, AccordionSummary, AccordionDetails, Popover} from '@mui/material';
import {Mic, MicOff, Videocam, VideocamOff, PersonRemove} from '@mui/icons-material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import {useSelector} from 'react-redux';
import {MediaStates, MediaTypes, qualityConstants} from "../../constants";

import './Video.css'
import {
    disconnectRemoteP,
    enableWTStats, isRemoteWTAudioEnabled, isRemoteWTVideoEnabled,
    onMosReport,
    offMosReport,
    pMediaStreamChanged,
    pStartSpeaking,
    pStopSpeaking, toggleWTRemoteAudio, toggleWTRemoteVideo,
    enableWTVideoStats, disableWTVideoStats,
} from "../../WT";

import SmartVideo from "./SmartVideo/SmartVideo";

const Video = ({participant, participants}) => {
    const videoRef = useRef();
    const {isAdmin} = useSelector(state => state.participant);
    const [isRemoteVideoEnabled, setIsRemoteVideoEnabled] = useState(true);
    const [isRemoteAudioEnabled, setIsRemoteAudioEnabled] = useState(true);
    const [isVideoStatsEnabled, setIsVideoStatsEnabled] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [videoStatInfo, setVideoStatInfo] = useState({
        frameStats: {
            numFrozenFrames: 0,
            numBlackFrames: 0,
            numFrames: 0
        }
    });

    const isRemoteVideoEnabledRef = useRef(isRemoteVideoEnabled);
    const isRemoteAudioEnabledRef = useRef(isRemoteAudioEnabled);
    const closePopover = () => {
        setAnchorEl(null);
    };
    const openPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const videoClasses = classnames({
        // 'local': participant.local,
        'video-content': true,
        'audio-only': (participant?.streamConstraints && !participant?.streamConstraints?.video) ? true : participant.stream.getTracks().length === 1,
        'p2': participants?.length === 2,
        'p3': participants?.length === 3,
        'p4': participants?.length === 4,
        'p5': participants?.length === 5,
        'p6': participants?.length === 6,
    });

    useEffect(() => {
        enableWTStats()

        const listener = ({participantId, stats}) => {
            if (participant.participantId === participantId) {
                const videoState = document.getElementById(`${participantId}-network-video`);
                const rtt = document.getElementById(`${participantId}-rtt`)

                if (rtt && stats.rtt) {
                    rtt.innerText = stats.rtt ? stats.rtt : 'N/A';
                }

                if (videoState) {
                    videoState.innerText = stats.video ? stats.video : 'N/A';
                }

                const audioState = document.getElementById(`${participantId}-network-audio`);

                if (audioState) {
                    audioState.innerText = stats.audio ? stats.audio : 'N/A';
                }

                const mosState = document.getElementById(`${participantId}-network-MOS`)

                if (mosState) {
                    mosState.innerText = stats.mos ? stats.mos.toFixed(2) : 'N/A';
                }

                if (stats.video === qualityConstants.bad || stats.audio === qualityConstants.bad) {
                    const node = document.getElementById(`${participant.participantId}-connection-container`);

                    if (node) {
                        node.style.backgroundColor = 'rgba(255, 0, 0, .6)';
                    }

                } else {
                    const node = document.getElementById(`${participant.participantId}-connection-container`);
                    if (node) {
                        node.style.backgroundColor = 'rgba(45,201,55, .6)';
                    }
                }
            }
        };

        onMosReport(listener);

        return () => offMosReport(listener);
    }, [participant.participantId])

    // checkRemoteStatus
    useEffect(() => {
        if (participant.settings.mutedAudio) {
            const microElemContainer = document.getElementById(`video-userinfo-container-${participant.participantId}`)

            if (microElemContainer) {
                const microElem = microElemContainer.querySelectorAll('div')[0]
                microElem.classList.add('muted')
            }
        }
        if (participant.settings.mutedVideo) {
            const avatar = document.getElementById(`${participant.participantId}-show-avatar`);
            const elem = document.getElementById(`${participant.participantId}-muted-video`);

            if (elem) {
                elem.style.display = 'block'
            }

            if (avatar) {
                avatar.style.display = 'block';
            }
        }
    }, [participant])

    useEffect(() => {
        isRemoteAudioEnabledRef.current = isRemoteAudioEnabled
    }, [isRemoteAudioEnabled])

    useEffect(() => {
        isRemoteVideoEnabledRef.current = isRemoteVideoEnabled
    }, [isRemoteVideoEnabled])

    useEffect(() => {
        const offPStartSpeaking = pStartSpeaking((participantId) => {
            if (participant.participantId === participantId) {
                const elem = document.getElementById(participantId);

                if (elem) {
                    elem.classList.add('active-speaker')
                }
            }
        })

        const offPStopSpeaking = pStopSpeaking((participantId) => {
            if (participant.participantId === participantId) {
                const elem = document.getElementById(participantId);

                if (elem) {
                    elem.classList.remove('active-speaker')
                }
            }
        })

        const offPMediaStreamChanged = pMediaStreamChanged(({participantId, mediaState, mediaType}) => {
            console.log({participantId, mediaState, mediaType})
            if (participant.participantId === participantId) {
                if (mediaType === MediaTypes.AUDIO && isRemoteAudioEnabledRef.current) {
                    const microElemContainer = document.getElementById(`video-userinfo-container-${participant.participantId}`)

                    if (mediaState === MediaStates.DISABLED) {
                        if (microElemContainer) {
                            const microElem = microElemContainer.querySelectorAll('div')[0]
                            microElem.classList.add('muted')
                        }
                    } else {
                        if (microElemContainer) {
                            const microElem = microElemContainer.querySelectorAll('div')[0]
                            microElem.classList.remove('muted')
                        }
                    }
                } else if (mediaType === MediaTypes.VIDEO && isRemoteVideoEnabledRef.current) {
                    if (mediaState === MediaStates.DISABLED) {
                        const avatar = document.getElementById(`${participant.participantId}-show-avatar`);
                        const elem = document.getElementById(`${participant.participantId}-muted-video`);

                        if (elem) {
                            elem.style.display = 'block'
                        }

                        if (avatar) {
                            avatar.style.display = 'block';
                        }
                    } else {
                        const avatar = document.getElementById(`${participant.participantId}-show-avatar`);
                        const elem = document.getElementById(`${participant.participantId}-muted-video`);

                        if (elem) {
                            elem.style.display = 'none'
                        }

                        if (avatar) {
                            avatar.style.display = 'none';
                        }
                    }
                }
            }
        })

        return () => {
            offPStartSpeaking();
            offPStopSpeaking();
            offPMediaStreamChanged();
        }
    }, [participant.participantId])

    const videoElementClasses = classnames({
        'participant-video': true,
        'flipVideoX': !!participant.local,
    });
    const ondblclick = e => {
        if (!document.fullscreenElement) {
            if (e.target.requestFullscreen) {
                e.target.requestFullscreen();
            } else if (e.target.mozRequestFullScreen) { /* Firefox */
                e.target.mozRequestFullScreen();
            } else if (e.target.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                e.target.webkitRequestFullscreen();
            } else if (e.target.msRequestFullscreen) { /* IE/Edge */
                e.target.msRequestFullscreen();
            }

            e.target.controls = false;
        } else {
            document.exitFullscreen();
            e.target.controls = false;
        }
    };

    const removeParticipant = () => {
        disconnectRemoteP(participant.participantId);
    }

    const toggleVideoStats = () => {
        const newState = !isVideoStatsEnabled;
        setIsVideoStatsEnabled(newState);
        if (newState) {
            enableWTVideoStats(videoRef.current, setVideoStatInfo);
        } else {
            disableWTVideoStats(videoRef.current);
        }
    }

    const toggleRemoteVideo = () => {
        toggleWTRemoteVideo(participant.participantId)
        if (!isRemoteWTVideoEnabled(participant.participantId)) {
            const avatar = document.getElementById(`${participant.participantId}-show-avatar`);
            const elem = document.getElementById(`${participant.participantId}-muted-video`);

            if (elem) {
                elem.style.display = 'block'
            }

            if (avatar) {
                avatar.style.display = 'block';
            }

            setIsRemoteVideoEnabled(false)
        } else {
            const avatar = document.getElementById(`${participant.participantId}-show-avatar`);
            const elem = document.getElementById(`${participant.participantId}-muted-video`);

            if (elem) {
                elem.style.display = 'none'
            }

            if (avatar) {
                avatar.style.display = 'none';
            }

            setIsRemoteVideoEnabled(true)
        }
    }

    const toggleRemoteAudio = () => {
        toggleWTRemoteAudio(participant.participantId);

        if (!isRemoteWTAudioEnabled(participant.participantId)) {
            const node = document.getElementById(`${participant.participantId}-muted-microphone`);

            if (node) {
                node.style.display = 'block';
            }


            setIsRemoteAudioEnabled(false);
        } else {
            const node = document.getElementById(`${participant.participantId}-muted-microphone`);

            if (node) {
                node.style.display = 'none';
            }


            setIsRemoteAudioEnabled(true);
        }
    }

    return (
        <div className={videoClasses}>
            <div
                id={`videospace-${participant.participantId}`}
                className='video-space'
            >
                <SmartVideo ref={videoRef} className={videoElementClasses} stream={participant.stream} isLocal={participant.local} onDoubleClick={ondblclick} participantId={participant.participantId} />
                <div
                    id={'video-userinfo-container-' + participant.participantId}
                    className={`video-userinfo-container ${participant?.streamConstraints && !participant?.streamConstraints?.video ? 'audio-only' : ''} ${participant.local ? 'local' : ''}`}>
                    <div>
                    </div>
                    <div>{`${participant.participantName} ${participant.local ? ' (me)' : ''}`}</div>
                </div>
                <div id={`${participant.participantId}-show-avatar`} className='video-container-background'>
                    <div className='avatar-image'>
                        <img src={'/avatar.png'} alt='avatar' className='video-container-avatar'/>
                    </div>
                </div>

                <div className='video-container-avatar-only'>
                </div>
            </div>
            <>
                <div className='top-bar'>
                    <div
                        id={`${participant.participantId}-connection-container`} className='connection-container'
                        tabIndex="0"
                        onMouseEnter={openPopover}
                        // component="div"

                    >
                        <div className='connection-image'>
                        </div>
                    </div>

                    <Popover
                        id={`${participant.participantId}-connection-popover`}
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        onClose={closePopover}
                    >
                        <Card className='network-monitoring-container'
                              sx={{padding: 1, paddingLeft: 2, paddingRight: 2}}>
                            <div className='network-item'>
                                <div>Video State:</div>
                                <div id={`${participant.participantId}-network-video`} className='network-value'>N/A
                                </div>
                            </div>

                            <div className='network-item'>
                                <div>Audio State:</div>
                                <div id={`${participant.participantId}-network-audio`} className='network-value'>N/A
                                </div>
                            </div>
                            <div className='network-item'>
                                <div>MOS:</div>
                                <div id={`${participant.participantId}-network-MOS`} className='network-value'>N/A</div>
                            </div>
                            <div className='network-item'>
                                <div>Rtt:</div>
                                <div id={`${participant.participantId}-rtt`} className='network-value'>N/A</div>
                            </div>
                            <div className='network-item'>
                                <Accordion>
                                    <AccordionSummary>
                                        <div>Frame Stats:</div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className='network-item'>
                                            <div>numFrames:</div>
                                            <div id={`${participant.participantId}-numFrames`}
                                                 className='network-value'>
                                                {videoStatInfo.frameStats.numFrames}
                                            </div>
                                        </div>
                                        <div className='network-item'>
                                            <div>numBlackFrames:</div>
                                            <div id={`${participant.participantId}-numBlackFrames`}
                                                 className='network-value'>
                                                {videoStatInfo.frameStats.numBlackFrames}
                                            </div>
                                        </div>
                                        <div className='network-item'>
                                            <div>numFrozenFrames:</div>
                                            <div id={`${participant.participantId}-numFrozenFrames`}
                                                 className='network-value'>
                                                {videoStatInfo.frameStats.numFrozenFrames}
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </Card>
                    </Popover>
                </div>
            </>

            {!participant.local ? (
                <>
                    <div className='user-control' tabIndex="0">
                        <ManageAccountsIcon fontSize='large'/>
                    </div>

                    <div className='user-control-menu-container'>
                        <div className='menu-item' onClick={toggleRemoteAudio} tabIndex="0">
                            {isRemoteAudioEnabled ? (
                                <>
                                    <MicOff fontSize='large' sx={{marginRight: 1}}/>
                                    Mute for myself
                                </>
                            ) : (
                                <>
                                    <Mic fontSize='large' sx={{marginRight: 1}}/>
                                    Unmute for myself
                                </>
                            )}
                        </div>
                        <div className='menu-item' onClick={toggleRemoteVideo} tabIndex="0">
                            {isRemoteVideoEnabled ? (<>
                                <VideocamOff fontSize='large' sx={{marginRight: 1}}/>
                                Turn off video
                            </>) : (<>
                                <Videocam fontSize='large' sx={{marginRight: 1}}/>
                                Turn on video
                            </>)}
                        </div>

                        <div className='menu-item' onClick={toggleVideoStats} tabIndex="0">
                            {isVideoStatsEnabled ? (<>
                                Turn off video stats
                            </>) : (<>
                                Turn on video stats
                            </>)}
                        </div>

                        {isAdmin ? (<div className='menu-item' onClick={removeParticipant} tabIndex="0">
                            <PersonRemove fontSize='large' sx={{marginRight: 1}}/>
                            Disconnect participant
                        </div>) : null}
                    </div>
                </>
            ) : null}
        </div>
    )
};

export default Video
