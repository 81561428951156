export default [
    '#333', '#0442bf', '#f38630', '#556270', '#4ecdc4', '#005f6b', '#ff6b6b',
    '#c44d58', '#774f38', '#e08e79', '#edc951', '#cdb380', '#036564', '#033649',
    '#031634', '#490a3d', '#bd1550', '#e97f02', '#f8ca00', '#594f4f', '#547980',
    '#45ada8', '#69d2e7', '#6a4a3c', '#cc333f', '#e94e77', '#c6a49a', '#8fbe00', 
    '#3fb8af', '#7fc7af', '#ff9e9d', '#d97d0d', '#732002', '#948c75', '#eb6841',
    '#d68189', '#1c140d', '#fc9d9a', '#fe4365', '#d95b43', '#c02942', '#343838',
    '#ff3d7f', '#008c9e', '#fa6900', '#00dffc', '#2e2633', '#99173c', '#413e4a',
    '#73626e', '#b38184', '#f0b49e', '#f7e4be', '#ff4e50', '#fc913a', '#f9d423',
    '#99b898', '#fecea8', '#ff847c', '#2a363b', '#f2ab27', '#655643', '#e6ac27',
    '#555152', '#bf4d28', '#00a8c6', '#00b4cc', '#00a0b0', '#5cacf2', '#f2b705',
    '#f29f05', '#f2b8a2', '#323e40', '#d94d1a', '#7a6a53', '#6c5b7b', '#c06c84',
    '#355c7d', '#40c0cb', '#f67280',
];