import React, { useCallback, useState } from 'react';
import { IconButton, Grid, TextField, InputAdornment } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

import Emoji from './Emoji';

import './style.css';

const MsgInput = ({chat}) => {
    const [msg, setMsg] = useState('');

    const handleSend = () => {
        setMsg('');

        const trimmedMsg = msg.trim();

        if (!trimmedMsg) {
            return;
        }

        chat.publish(msg).then().catch(console.error);
    };

    const enterEmoji = useCallback((item) => {
        setMsg((prev) => [prev, item].join(''));
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={10} sx={{ position: 'relative' }}>
                <TextField
                    label="Message"
                    className='msgField'
                    value={msg}
                    onChange={(e) => setMsg(e.target.value)}
                    onKeyUp={(e) => {
                        e.stopPropagation();
                        if (e.keyCode === 13 && !e.shiftKey) {
                            handleSend();
                        }
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Emoji onSelect={enterEmoji} />
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid item xs={2} className='sendBtn'>
                <IconButton size="large" onClick={handleSend}>
                    <SendIcon
                        fontSize="large"
                        sx={{
                            marginRight: '-4px',
                            color: msg ? 'var(--sendBtnBg)' : 'default',
                        }}
                    />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default React.memo(MsgInput);
