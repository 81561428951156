import React, { useCallback, useMemo, useState } from 'react';
import { Box, Menu } from '@mui/material';
import classNames from 'classnames';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';

import list from './list';
import './style.css';

const Emoji = ({ onSelect }) => {
    const [opened, setOpened] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpen = (e) => {
        setAnchorEl(e.currentTarget);
        setOpened(true);
    };

    const handleClose = () => {
        setOpened(false);
    };

    const handleClick = useCallback((e) => {
        const item = list[Number(e.target.dataset.index)];

        if (item) {
            onSelect(item);
        }
    }, [onSelect]);

    const emojis = useMemo(
        () =>
            list.map((item, i) => (
                <div key={item} className='emoji-item' data-index={i} onClick={handleClick}>
                    {item}
                </div>
            )),
        [handleClick],
    );

    return (
        <Box className='emoji-root'>
            <SentimentSatisfiedAltIcon
                className={classNames('emoji-openIcon', { 'emoji-openIcon_opened': opened })}
                onClick={handleOpen}
            />

            <Menu id="basic-menu" anchorEl={anchorEl} open={opened} onClose={handleClose} className='emoji-menuRoot'>
                <Box className='emoji-card'>{emojis}</Box>
            </Menu>
        </Box>
    );
};

export default React.memo(Emoji);
